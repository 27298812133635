import React, { FunctionComponent, useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';
import projectService from '../../services/project.service';
import IProject from '../../types/project.type';

interface ModalProps {
	onClose: () => void;
    //landlordId?: number;
	//userObj: IUser;
}

export const ProjectCreateModal: FunctionComponent<ModalProps> = props => {
	const [modelInfo, setModelInfo] = useState<IProject>({});
	
	const onCreate = () => {
		projectService.create(modelInfo).then(
			response => {
				setModelInfo({
					name:'',
				});
				toast.success("Thêm mới thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	// useEffect(()=>{	
	// 	setUserInfo(props.userObj);
	// },[])

	return (
		<React.Fragment>
			<Message>Tạo mới Project</Message>
			<Row>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Name</Form.Label>
					<Form.Control type='text' placeholder='Nhập Tên Dự án' onChange={(e)=>{setModelInfo({...modelInfo, name:e.target.value})}} value={modelInfo.name??''}/>
				</Form.Group>
			</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className='mb-2'>
						<Button onClick={()=>{onCreate()}} variant="primary" type="submit">Save</Button>
					</Form.Group>
				</Col>
			</Row>
			<div className='modal-footer'>
				<Button variant="secondary" onClick={props.onClose}>Close</Button>
			</div>
		</React.Fragment>
	);
};