import { Component } from "react";
import EventBus from "../common/EventBus";
import IUser from "../types/user.type";
import userService from "../services/user.service";
import Table from 'react-bootstrap/Table';
import { Container } from "react-bootstrap";
import { Modal } from "./modal/modal";
import { UserDetailModal } from "./modal-components/user-detail-modal";
import { UserCreateModal } from "./modal-components/user-create-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons'
import authService from "../services/auth.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  //currentUser:IUser
};

type State = {
  users: IUser[];
  isShownDetailModal:boolean;
  isShownCreateModal:boolean;
  userSelected: number | null;
}

//const [isShownDetailModal, setIsShownDetailModal] = useState<boolean>(false);
//const [userSelected, setUserSelected] = useState<number>();

export default class Users extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      users: [],
      isShownDetailModal: false,
      isShownCreateModal: false,
      userSelected: null
    };
  }

  currentUser:IUser = authService.getCurrentUser();

  componentDidMount() {
    userService.getAll().then(
      response => {
        this.setState({
          users: response.data
        });
      },
      error => {
        this.setState({
          
        });
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <Container>
        <ToastContainer />
        <Modal
          isShown={this.state.isShownDetailModal}
          hide={()=>{this.setState({isShownDetailModal:false})}}
          headerText={''}
          //landlordId={landlordIdSelected}
          modalContent={
            <UserDetailModal 
              onClose={()=>{this.setState({isShownDetailModal:false})}} 
              userObj={this.state.users.find(l=>l.id === this.state.userSelected)??{}}
            />
          }
        />
        <Modal
          isShown={this.state.isShownCreateModal}
          hide={()=>{this.setState({isShownCreateModal:false})}}
          headerText={''}
          //landlordId={landlordIdSelected}
          modalContent={
            <UserCreateModal 
              onClose={()=>{this.setState({isShownCreateModal:false})}} 
              //userObj={this.state.users.find(l=>l.id === this.state.userSelected)??{}}
            />
          }
        />
        <h4>Users</h4>
        <div className="btn-toolbar kv-grid-toolbar toolbar-container float-right">
          <div className="btn-group mb-2">
            <a onClick={()=> this.componentDidMount() } className="btn btn-secondary"><FontAwesomeIcon icon={faRefresh} /></a>
            {
              this.currentUser.username === 'admin' ? <a onClick={()=> this.setState({isShownCreateModal:true}) } className="btn btn-success"><FontAwesomeIcon icon={faPlus} /> Tạo mới</a> : ''
            }
          </div>
        </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Trạng thái</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.status===true?'Active':'In-Active'}</td>
                  <td>
                    <a onClick={()=>{this.setState({userSelected:user.id ?? null, isShownDetailModal:true}) }}><FontAwesomeIcon icon={faEdit} /></a>
                  </td>
                </tr>
              ))}
            </tbody>
            </Table>
      </Container>
    );
  }
}
