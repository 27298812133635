import axios from 'axios';
import ILandlord from '../types/landlord.type';
import ILandlordUpload from '../types/landlordupload.type';
import authHeader from './auth-header';

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "https://api.ytecaocap.com/";
//const API_URL = "http://localhost:4000/";

class LandlordService {
  getAll(searchObj:URLSearchParams) {
    const queryString = new URLSearchParams(searchObj).toString();
    return axios.get(API_URL + 'landlords?' + queryString, { headers: authHeader() });
  }

  getById(id:number){
    return axios.get(API_URL + 'landlords/' + id, { headers: authHeader() });
  }

  update(obj?:ILandlord){
    return axios.post(API_URL + 'landlords/' + obj?.id, obj, { headers: authHeader() })
  }

  create(obj?:ILandlord){
    return axios.post(API_URL + 'landlords', obj, { headers: authHeader() })
  }

  postFileAsync(obj:ILandlordUpload){
    // Create an object of formData
    const formData = new FormData();
 
    // Update the formData object
    formData.append("file_data",obj.file_data);
    formData.append("project_id",''+obj.project_id);
    formData.append("tower_id",''+obj.tower_id);

    return axios.post(API_URL + 'landlords/PostFileAsync', formData, { headers: authHeader() })
  }
}

export default new LandlordService();
