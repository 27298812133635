import authService from "../services/auth.service";
import { Link } from "react-router-dom";
import EventBus from "../common/EventBus";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import IUser from "../types/user.type";
import { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChartColumn, faWarehouse, faBuilding, faFileExcel, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

interface Props {
	authUser: IUser | undefined;
}

export const TopMenu:FunctionComponent<Props> = props => {
  //const [userInfo, setUserInfo] = useState<IUser | undefined>(props.authUser);
  //const authUser = authService.getCurrentUser();

  const logOut = () => {
    //authService.logout();
    EventBus.dispatch('logout');
  }

  // only show nav when logged in
  if (!props.authUser) return null;

  return (
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">CENTRAL HOMES</Link>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">

          </Nav>
          <Nav>
            <NavDropdown className="user-menu" title={props.authUser.username}>
              <Link className="dropdown-item" to={"/users"}><FontAwesomeIcon icon={faUser} /> Users</Link>
              <Link className="dropdown-item" to={"/reports"}><FontAwesomeIcon icon={faChartColumn} /> Báo Cáo</Link>
              {
                props.authUser.username == 'admin' &&
                <>
                  <Link className="dropdown-item" to={"/projects"}><FontAwesomeIcon icon={faWarehouse} /> QL Dự Án</Link>
                  <Link className="dropdown-item" to={"/towers"}><FontAwesomeIcon icon={faBuilding} /> QL Tòa Tháp</Link>
                  <Link className="dropdown-item" to={"/multi-uploads"}><FontAwesomeIcon icon={faFileExcel} /> Excel Uploads</Link>
                </>
              }
              <NavDropdown.Divider />
              <Link className="dropdown-item" onClick={logOut} to={"/login"}><FontAwesomeIcon icon={faRightFromBracket} /> Logout</Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}
