import React, { FunctionComponent, useEffect, useState } from 'react';
import IUser from '../../types/user.type';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';
import authService from '../../services/auth.service';
import userService from '../../services/user.service';

interface ModalProps {
	onClose: () => void;
	userObj: IUser;
}

export const UserDetailModal: FunctionComponent<ModalProps> = props => {
	const [userInfo, setUserInfo] = useState<IUser>(props.userObj);
	const currentUser = authService.getCurrentUser();
	
	const onUpdate = () => {
		userService.update(userInfo).then(
			response => {
				//alert(response.data.message);
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	// useEffect(()=>{	
	// 	setUserInfo(props.userObj);
	// },[])

	return (
		<React.Fragment>
			<Message>{(userInfo != null && userInfo.id != null) ? 'Cập nhật thông tin' : 'Tạo mới User'}</Message>
			<Row>
				<Col>
					<h6>User ID is #{userInfo?.id}</h6>
				</Col>
			</Row>
			<Row>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Username</Form.Label>
					<Form.Control type='text' disabled placeholder='Nhập Username' className='form-control' value={userInfo?.username}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>New Password</Form.Label>
					<Form.Control type="password" placeholder='Nhập Password cần đổi' onChange={(e)=>{setUserInfo({...userInfo, password:e.target.value})}} className='form-control' value={userInfo.password ?? ''}/>
				</Form.Group>
				{
					currentUser.username==='admin'&&
					<Form.Group>
						<Form.Label>Trạng Thái </Form.Label>
						<div className='mb-2'>
							<Form.Check
								inline
								name='status'
								type='radio'
								label={'Active'}
								checked={userInfo?.status}
								onChange={(e)=>{setUserInfo({...userInfo, status:e.target.checked})}}
							/>
							<Form.Check
								inline
								name='status'
								type='radio'
								label={'In-Active'}
								checked={!userInfo?.status}
								onChange={(e)=>{setUserInfo({...userInfo, status:!e.target.checked})}}
							/>
						</div>
					</Form.Group>
				}
			</Col>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>First Name</Form.Label>
					<Form.Control placeholder='Nhập First Name' onChange={(e)=>{setUserInfo({...userInfo, firstName:e.target.value})}} className='form-control' value={userInfo?.firstName}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Last Name</Form.Label>
					<Form.Control placeholder='Nhập Last Name' onChange={(e)=>{setUserInfo({...userInfo, lastName:e.target.value})}} className='form-control' value={userInfo?.lastName}/>
				</Form.Group>
			</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className='mb-2'>
						<Button onClick={()=>{onUpdate()}} variant="primary" type="submit">Save</Button>
					</Form.Group>
				</Col>
			</Row>
			<div className='modal-footer'>
				<Button variant="secondary" onClick={props.onClose}>Close</Button>
			</div>
		</React.Fragment>
	);
};