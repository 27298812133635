import axios from "axios";
import { redirect } from "react-router-dom";

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(API_URL + "users/authenticate", {
        username,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("auth_user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("_projects");
    localStorage.removeItem("_refdatas");
    localStorage.removeItem("_towers");
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_URL + "users/register", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("auth_user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
