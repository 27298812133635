import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import ILandlord from '../../types/landlord.type';
import IRefData from '../../types/refdata.type';
import IProject from "../../types/project.type"
import ITower from "../../types/tower.type";
import landlordService from "../../services/landlord.service";
import refdataService from "../../services/refdata.service";
import projectService from "../../services/project.service";
import towerService from "../../services/tower.service";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';

interface LandlordCreateModalProps {
	onClose: () => void;
    //landlordId?: number;
	//landlordObj?: ILandlord;
}

export const LandlordCreateModal: FunctionComponent<LandlordCreateModalProps> = props => {
	const [landlordInfo, setLandlordInfo] = useState<ILandlord>();
	const [refDatas, setRefDatas] = useState<IRefData[]>([])
	const [projects, setProjects] = useState<IProject[]>([])
  	const [towers, setTowers] = useState<ITower[]>([])

	const _contractDateExp = landlordInfo?.expContractFormat != null ? new Date(landlordInfo?.expContractFormat ?? '') : null;

	const onCreateLandlord = () => {
		landlordService.create(landlordInfo).then(
			response => {
				//console.log('success created' + response.data);
				toast.success("Thêm mới thành công!", {theme: "colored"})
				props.onClose();
			},
			error => {

			}
		)
	}

	useEffect(()=>{	
		const fetchRefDatas = async () => {
			const refdatas = await refdataService.getAll()
			setRefDatas(refdatas);
		  };    
		fetchRefDatas();
		
		const fetchProjects = async () => {
			const projects = await projectService.getAll()
			setProjects(projects);
		  };    
		fetchProjects();

		const fetchTowers = async () => {
			const towers = await towerService.getAll()
			setTowers(towers);
		};    
    	fetchTowers();
	},[])

	return (
		<React.Fragment>
			<Message>Tạo Mới Khách Hàng</Message>
			<div className='row'>
				<div className='col-md-6 cod-sm-6 col-12'>
					<div className='form-group'>
						<label className='required'>Tên Khách Hàng</label>
						<input placeholder='Nhập Tên Khách Hàng' onChange={(e)=>{setLandlordInfo({...landlordInfo, name:e.target.value})}} className='form-control' value={landlordInfo?.name ?? ''}/>
					</div>
					<div className='form-group'>
						<label className='required'>Dự Án</label>
						<select value={landlordInfo?.projectID ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, projectID:+e.target.value, towerID:null }) } } className="form-control">
							<option value="0">- Chọn Dự Án -</option>
							{
								projects.map(_item =>
									<option key={_item.id} value={_item.id}>{_item.name}</option>
								)
							}
						</select>
					</div>
					<div className='form-group'>
						<label className='required'>Tháp</label>
						<div>
							{
								landlordInfo?.projectID ? 
								<div className='btn-group w-100-overflow'>
								{
									towers.filter(_r => landlordInfo?.projectID === _r.projectID).map(_item =>
										<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, towerID:_item.id}) } } className={`${landlordInfo?.towerID===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
									)
								}
								</div>
								: <div className='text-muted'>- Vui lòng chọn Dự án -</div>
							}
						
						</div>
					</div>
					<div className='form-group'>
						<label>Mã Căn</label>
						<input placeholder='Nhập Mã Căn' onChange={(e)=>{setLandlordInfo({...landlordInfo, maCan:e.target.value})}} className='form-control' value={landlordInfo?.maCan ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Số ĐT <small className='text-muted'>Mỗi Số ĐT cách nhau bằng dấu phẩy `,`</small></label>
						<input placeholder='Nhập Số ĐT' onChange={(e)=>{setLandlordInfo({...landlordInfo, phoneNo:e.target.value})}} className='form-control' value={landlordInfo?.phoneNo ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Email <small className='text-muted'>Mỗi Email cách nhau bằng dấu phẩy `,`</small></label>
						<input placeholder='Nhập Email' onChange={(e)=>{setLandlordInfo({...landlordInfo, email:e.target.value})}} className='form-control' value={landlordInfo?.email ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Địa Chỉ</label>
						<input placeholder='Nhập Địa Chỉ' onChange={(e)=>{setLandlordInfo({...landlordInfo, address:e.target.value})}} className='form-control' value={landlordInfo?.address ?? ''}/>
					</div>
				</div>
				<div className='col-md-6 cod-sm-6 col-12'>
					<div className='form-group'>
						<label>Giá Bán</label>
						<input placeholder='Nhập Giá Bán' onChange={(e)=>{setLandlordInfo({...landlordInfo, giaBan:e.target.value})}} className='form-control' value={landlordInfo?.giaBan ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Giá Thuê</label>
						<input placeholder='Nhập Giá Thuê' onChange={(e)=>{setLandlordInfo({...landlordInfo, giaThue:e.target.value})}} className='form-control' value={landlordInfo?.giaThue ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Diện Tích</label>
						<input placeholder='Nhập Diện Tích' onChange={(e)=>{setLandlordInfo({...landlordInfo, dienTich:e.target.value})}} className='form-control' value={landlordInfo?.dienTich ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Tầng</label>
						<input placeholder='Nhập số Tầng' onChange={(e)=>{setLandlordInfo({...landlordInfo, floorNo:e.target.value})}} className='form-control' value={landlordInfo?.floorNo ?? ''}/>
					</div>
					<div className='form-group'>
						<label>Căn</label>
						<input placeholder='Nhập Trục Căn' onChange={(e)=>{setLandlordInfo({...landlordInfo, trucCan:e.target.value})}} className='form-control' value={landlordInfo?.trucCan ?? ''}/>
					</div>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='form-group'>
								<label>Ngày hết hạn HĐ</label>
								<div>
									<DatePicker dateFormat="dd/MM/yyyy" selected={_contractDateExp} onChange={(date)=>{setLandlordInfo({...landlordInfo, expContractFormat:date})}} className='form-control' />
								</div>
							</div>
						</div>
						<div className='col-sm-6'>
							<div className='form-group'>
								<label>Ghi chú HĐ</label>
								<input placeholder='Ghi Chú cho HĐ' onChange={(e)=>{setLandlordInfo({...landlordInfo, contractNote:e.target.value})}} className='form-control' value={landlordInfo?.contractNote ?? ''}/>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-12'>
					<div className='form-group row'>
						<label className='col-sm-2'>Tình Trạng</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'tinh_trang' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, tinhTrangCd:_item.id}) } } className={`${landlordInfo?.tinhTrangCd===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group row'>
						<label className='col-sm-2'>Số PN</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'so_pn' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, soPhongNgu:_item.id}) } } className={`${landlordInfo?.soPhongNgu===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group row'>
						<label className='col-sm-2'>Hướng</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'huong' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, huongCd:_item.id}) } } className={`${landlordInfo?.huongCd===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group row'>
						<label className='col-sm-2'>Nội Thất</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'noi_that' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, noiThatCd:_item.id}) } } className={`${landlordInfo?.noiThatCd===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group row'>
						<label className='col-sm-2'>Loại Hình</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'loai_hinh' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, loaiHinhCd:_item.id}) } } className={`${landlordInfo?.loaiHinhCd===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group'>
						<label>Trạng Thái</label>
						<div className='btn-group w-100-overflow'>
						{
							refDatas.filter(_r => 'trang_thai' === ''+_r.refCd).map(_item =>
								<a key={_item.id} onClick={()=> { setLandlordInfo({...landlordInfo, trangThaiCd:_item.id}) } } className={`${landlordInfo?.trangThaiCd===_item.id?'active':''} btn btn-outline-secondary`}>{_item.name}</a>
							)
						}
						</div>
					</div>
					<div className='form-group mt-2'>
						<label>Ghi Chú</label>
						<textarea placeholder='Nhập Ghi Chú khác' onChange={(e)=>{setLandlordInfo({...landlordInfo, note:e.target.value})}} className='form-control' value={landlordInfo?.note ?? ''}/>
					</div>
					<div className='form-group'>
						<button onClick={() => { onCreateLandlord() }} type="button" className="btn btn-primary mt-2 mr-2 col">Save</button>
					</div>
				</div>
			</div>
			<div className='modal-footer'>
				{/* <YesButton onClick={props.onConfirm}>Yes</YesButton> */}
				{/* <NoButton onClick={props.onClose}>Close</NoButton> */}
				<button type="button" className="btn btn-secondary" onClick={props.onClose}>Close</button>
			</div>
		</React.Fragment>
	);
};