import axios from 'axios';
import authHeader from './auth-header';
import ITower from '../types/tower.type'

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class TowerService {
  getAll = async() =>  {
    const towersStr = localStorage.getItem("_towers");
    if (towersStr) 
      return JSON.parse(towersStr);
    else
    {
      return axios.get(API_URL + 'towers?status=true', { headers: authHeader() })
          .then(response => {
            localStorage.setItem("_towers", JSON.stringify(response.data));
            return response.data;
          });
    }
  }

  getAllForManage = async() => {
    return axios.get(API_URL + 'towers', { headers: authHeader() });
  }

  create(model:ITower){
    return axios.post(API_URL + 'towers', model, { headers: authHeader() })
  }

  update(model:ITower){
    return axios.post(API_URL + 'towers/' + model.id, model, { headers: authHeader() })
  }
}

export default new TowerService();
