import { Component } from "react";

import EventBus from "../common/EventBus";
import projectService from "../services/project.service";
import towerService from "../services/tower.service";
import Table from 'react-bootstrap/Table';
import { Container } from "react-bootstrap";
import IProject from "../types/project.type";
import ITower from "../types/tower.type";
import IUploadResponse from "../types/uploadresponse.type";
import ILandlordUpload from "../types/landlordupload.type";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { Form, Col, Row, Button } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import landlordService from "../services/landlord.service";

type Props = {};

type State = {
  projects: IProject[];
  projectSelected: number;
  towers: ITower[];
  towerSelected: number;
  fileSelected: File|null;
  submitBtnTxt: string;
  uploadResponse: IUploadResponse | null;
}

export default class Towers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      projects: [],
      projectSelected: 0,
      towers: [],
      towerSelected: 0,
      fileSelected: null,
      submitBtnTxt: 'Submit',
      uploadResponse: null
    };
  }

  onSubmit = () => {
    this.setState({submitBtnTxt:'Đang xử lý', uploadResponse:null})
    //console.log(this.state.fileSelected);
    landlordService.postFileAsync({
      project_id: this.state.projectSelected,
      tower_id: this.state.towerSelected,
      file_data: this.state.fileSelected!
    }).then(
      response => {
        this.setState({uploadResponse:response.data.result, submitBtnTxt:'Submit', fileSelected: null})
      },
      error => {
        this.setState({submitBtnTxt:'Submit', fileSelected: null})
      }
    );
	}

  componentDidMount() {
    projectService.getAllForManage().then(
      response => {
        this.setState({
          projects: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    towerService.getAllForManage().then(
      response => {
        this.setState({
          towers: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <Container>
        <h4>Thêm mới hàng loạt Khách hàng</h4>
        <Alert variant="warning">
          <Alert.Heading>Lưu ý</Alert.Heading>
          <p>Đây là tính năng uploads hàng loạt Khách hàng vào hệ thống, vui lòng chuẩn bị thật kỹ file excel đầu vào trước khi bấm submit.</p>
          <p><b>1.</b> Mỗi file excel chỉ dành cho Một (01) tòa/tháp trong Dự án. Nếu có nhiều Tòa tháp, thì mỗi tòa/tháp là một file riêng.</p>
          <p><b>2.</b> Chuẩn bị file excel (.xlsx) theo <b><i><a target="_blank" href="https://docs.google.com/spreadsheets/d/1R9Rkl4_WrsA9LbDTUwbR9KBDVqqbCvlH/edit?usp=sharing&ouid=105973661665046924448&rtpof=true&sd=true">mẫu này</a></i></b></p>
          <p><b>3.</b> Bấm chọn Dự Án bên dưới, đến chọn Tòa/tháp, và chọn file excel chứa dữ liệu. Cuối cùng là bấm Submit để upload dữ liệu vào hệ thống.</p>
        </Alert>
        <div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="required" column sm="2">Dự Án</Form.Label>
            <Col sm="4">
              <Form.Select value={this.state.projectSelected} onChange={(e)=> { this.setState({ projectSelected:+e.target.value, towerSelected:0 }) } } className="form-control">
                <option value="0">- Chọn Dự Án -</option>
                {
                  this.state.projects.map(_item =>
                    <option key={_item.id} value={_item.id}>{_item.name}</option>
                  )
                }
              </Form.Select>
            </Col>
          </Form.Group>
          {
            this.state.projectSelected != 0 &&
            <Form.Group as={Row} className="mb-3">
              <Form.Label className="required" column sm="2">Tòa tháp</Form.Label>
              <Col sm="4">
                <Form.Select value={this.state.towerSelected} onChange={(e)=> { this.setState({ towerSelected:+e.target.value }) } } className="form-control">
                  <option value="0">- Chọn Tòa tháp -</option>
                  {
                    this.state.towers.filter(x=>x.projectID===this.state.projectSelected).map(_item =>
                      <option key={_item.id} value={_item.id}>{_item.name}</option>
                    )
                  }
                </Form.Select>
              </Col>
            </Form.Group>
          }
          {
            this.state.towerSelected != 0 &&
            <Form.Group as={Row} className="mb-3">
              <Form.Label className="required" column sm="2">Excel file</Form.Label>
              <Col sm="4">
                <input onChange={(e)=>{this.setState({fileSelected:e.target.files?e.target.files[0]:null})}} type="file" />
              </Col>
            </Form.Group>
          }
          <Row>
            <Col sm="2"></Col>
            <Col sm="4">
              <Button onClick={()=>{this.onSubmit();}} disabled={this.state.fileSelected===null} type="submit">{this.state.submitBtnTxt}</Button>
            </Col>
          </Row>
        </div>
        {
          this.state.uploadResponse!==null &&
          <div className="mt-3">
            <Alert variant="success">
              <Alert.Heading>Kết quả xử lý</Alert.Heading>
              <p>Có <b>{this.state.uploadResponse.successCount}/{this.state.uploadResponse.successCount + this.state.uploadResponse.landlordErrors.length}</b> dòng được thêm thành công</p>
            </Alert>
            <Alert variant="danger">
                <Alert.Heading>Các dòng dữ liệu KHÔNG thành công</Alert.Heading>
                <p>Vui lòng ghi nhận lại (copy/ chụp màn hình) để xử lý upload lại các dữ liệu không thành công này</p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Dòng <small>(Của Excel)</small></th>
                      <th>Mã Căn</th>
                      <th>Tên KH</th>
                      <th>SĐT</th>
                      <th>Email</th>
                      <th>Ghi chú</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadResponse.landlordErrors.map(it => (
                      <tr key={it.refId}>
                        <td>{it.refId}</td>
                        <td>{it.maCan}</td>
                        <td>{it.name}</td>
                        <td>{it.phoneNo}</td>
                        <td>{it.email}</td>
                        <td>{it.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Alert>
          </div>
        }
      </Container>
    );
  }
}
