import axios from 'axios';
import authHeader from './auth-header';
import ILandlordTracking from '../types/landlordtracking.type';

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class LandlordTrackingService {
  get(landlord_id:number) {
    return axios.get(API_URL + 'landlordtrackings?landlord_id=' + landlord_id, { headers: authHeader() });
  }

  update(landlordTrackingInfo:ILandlordTracking ){
    return axios.post(API_URL + 'landlordtrackings', landlordTrackingInfo, { headers: authHeader() })
  }
}

export default new LandlordTrackingService();
