import { Component } from "react";

import EventBus from "../common/EventBus";
import projectService from "../services/project.service";
import towerService from "../services/tower.service";
import Table from 'react-bootstrap/Table';
import { Container } from "react-bootstrap";
import IProject from "../types/project.type";
import { Modal } from "./modal/modal";
import { TowerCreateModal } from "./modal-components/tower-create-modal";
import { TowerDetailModal } from "./modal-components/tower-detail-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons'
import ITower from "../types/tower.type";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {};

type State = {
  projects: IProject[];
  projectSelected: number | null;
  towers: ITower[];
  towerSelected: number | null;
  isShownDetailModal:boolean;
  isShownCreateModal:boolean;
}

export default class Towers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      projects: [],
      projectSelected: null,
      towers: [],
      towerSelected: null,
      isShownDetailModal:false,
      isShownCreateModal:false,
    };
  }

  componentDidMount() {
    projectService.getAllForManage().then(
      response => {
        this.setState({
          projects: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    towerService.getAllForManage().then(
      response => {
        this.setState({
          towers: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <Container>
        <ToastContainer />
        <Modal
          isShown={this.state.isShownDetailModal}
          hide={()=>{this.setState({isShownDetailModal:false})}}
          headerText={''}
          modalContent={
            <TowerDetailModal 
              onClose={()=>{this.setState({isShownDetailModal:false})}} 
              modelObj={this.state.towers.find(l=>l.id === this.state.towerSelected)??{}}
              projectObj={this.state.projects.find(p=>p.id === this.state.projectSelected)??{}}
            />
          }
        />
        <Modal
          isShown={this.state.isShownCreateModal}
          hide={()=>{this.setState({isShownCreateModal:false})}}
          headerText={''}
          modalContent={
            <TowerCreateModal 
              onClose={()=>{this.setState({isShownCreateModal:false})}} 
              projectObj={this.state.projects.find(p=>p.id === this.state.projectSelected)??{}}
            />
          }
        />
        <h4>Danh sách Tòa Tháp</h4>
        <div className="float-left">
          <div className='form-group'>
              <select value={this.state.projectSelected ?? 0} onChange={(e)=> { this.setState({ projectSelected:+e.target.value, towerSelected:null }) } } className="form-control">
                <option value="0">- Chọn Dự Án -</option>
                {
                  this.state.projects.map(_item =>
                    <option key={_item.id} value={_item.id}>{_item.name}</option>
                  )
                }
              </select>
            </div>
        </div>
        <div className="btn-toolbar kv-grid-toolbar toolbar-container float-right">
          <div className="btn-group mb-2">
            <a onClick={()=> this.componentDidMount() } className="btn btn-secondary"><FontAwesomeIcon icon={faRefresh} /></a>
            <a onClick={()=> this.setState({isShownCreateModal:true}) } className="btn btn-success"><FontAwesomeIcon icon={faPlus} /> Tạo mới</a>
          </div>
        </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Tên Dự án</th>
                <th>Trạng thái</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.towers.filter(x=>x.projectID === this.state.projectSelected).map(it => (
                <tr key={it.id}>
                  <td>{it.id}</td>
                  <td>{it.name}</td>
                  <td>{it.status===true?'Active':'In-Active'}</td>
                  <td>
                    <a onClick={()=>{this.setState({towerSelected:it.id ?? null, isShownDetailModal:true}) }}><FontAwesomeIcon icon={faEdit} /></a>
                  </td>
                </tr>
              ))}
            </tbody>
            </Table>
      </Container>
    );
  }
}
