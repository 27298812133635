import React, { FunctionComponent, useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';
//import authService from '../../services/auth.service';
import towerService from '../../services/tower.service';
import IProject from '../../types/project.type';
import ITower from '../../types/tower.type';

interface ModalProps {
	onClose: () => void;
	modelObj: ITower;
	projectObj: IProject;
}

export const TowerDetailModal: FunctionComponent<ModalProps> = props => {
	const [modelInfo, setModelInfo] = useState<ITower>(props.modelObj);
	
	const onUpdate = () => {
		towerService.update(modelInfo).then(
			response => {
				//alert(response.data.message);
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	// useEffect(()=>{	
	// 	setUserInfo(props.userObj);
	// },[])

	return (
		<React.Fragment>
			<Message>Cập nhật Tòa Tháp</Message>
			<Row>
				<Col>
					<Form.Group className="mb-2">
						<Form.Label>Dự Án</Form.Label>
						<Form.Control type='text' disabled value={props.projectObj.name ?? ''}/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="mb-2">
						<Form.Label>Tower ID</Form.Label>
						<Form.Control type='text' disabled value={modelInfo.id??''}/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Tên Tòa Tháp</Form.Label>
					<Form.Control type='text' placeholder='Nhập Tên Tòa Tháp' onChange={(e)=>{setModelInfo({...modelInfo, name:e.target.value})}} value={modelInfo.name??''}/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Trạng Thái </Form.Label>
					<div className='mb-2'>
						<Form.Check
							inline
							name='status'
							type='radio'
							label={'Active'}
							checked={modelInfo.status ?? false}
							onChange={(e)=>{setModelInfo({...modelInfo, status:e.target.checked})}}
						/>
						<Form.Check
							inline
							name='status'
							type='radio'
							label={'In-Active'}
							checked={!modelInfo.status ?? false}
							onChange={(e)=>{setModelInfo({...modelInfo, status:!e.target.checked})}}
						/>
					</div>
				</Form.Group>
			</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className='mb-2'>
						<Button onClick={()=>{onUpdate()}} variant="primary" type="submit">Save</Button>
					</Form.Group>
				</Col>
			</Row>
			<div className='modal-footer'>
				<Button variant="secondary" onClick={props.onClose}>Close</Button>
			</div>
		</React.Fragment>
	);
};