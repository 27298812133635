import React, { FunctionComponent, useEffect, useState } from 'react';
import IUser from '../../types/user.type';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';
import userService from '../../services/user.service';

interface ModalProps {
	onClose: () => void;
    //landlordId?: number;
	//userObj: IUser;
}

export const UserCreateModal: FunctionComponent<ModalProps> = props => {
	const [userInfo, setUserInfo] = useState<IUser>({});
	
	const onCreate = () => {
		userService.create(userInfo).then(
			response => {
				setUserInfo({
					username:'',
					password:'',
					firstName:'',
					lastName:''
				});
				//alert(response.data.message);
				toast.success("Thêm mới thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	// useEffect(()=>{	
	// 	setUserInfo(props.userObj);
	// },[])

	return (
		<React.Fragment>
			<Message>Tạo mới User</Message>
			<Row>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Username</Form.Label>
					<Form.Control type='text' placeholder='Nhập Username' onChange={(e)=>{setUserInfo({...userInfo, username:e.target.value})}} value={userInfo?.username}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label>New Password</Form.Label>
					<Form.Control type="password" placeholder='Nhập Password cần đổi' onChange={(e)=>{setUserInfo({...userInfo, password:e.target.value})}} value={userInfo.password ?? ''}/>
				</Form.Group>
			</Col>
			<Col>
				<Form.Group className="mb-2">
					<Form.Label className='required'>First Name</Form.Label>
					<Form.Control placeholder='Nhập First Name' onChange={(e)=>{setUserInfo({...userInfo, firstName:e.target.value})}} className='form-control' value={userInfo?.firstName}/>
				</Form.Group>
				<Form.Group className="mb-2">
					<Form.Label className='required'>Last Name</Form.Label>
					<Form.Control placeholder='Nhập Last Name' onChange={(e)=>{setUserInfo({...userInfo, lastName:e.target.value})}} className='form-control' value={userInfo?.lastName}/>
				</Form.Group>
			</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className='mb-2'>
						<Button onClick={()=>{onCreate()}} variant="primary" type="submit">Save</Button>
					</Form.Group>
				</Col>
			</Row>
			<div className='modal-footer'>
				<Button variant="secondary" onClick={props.onClose}>Close</Button>
			</div>
		</React.Fragment>
	);
};