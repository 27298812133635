import axios from 'axios';
import IUser from '../types/user.type';
import authHeader from './auth-header';

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class UserService {
  getAll() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }

  create(user:IUser){
    return axios.post(API_URL + 'users', user, { headers: authHeader() })
  }

  update(user:IUser){
    return axios.post(API_URL + 'users/' + user.id, user, { headers: authHeader() })
  }

  delete(id:number){
    return axios.post(API_URL + 'users/' + id + 'delete', null, { headers: authHeader() })
  }
}

export default new UserService();
