import { Component } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import IUser from './types/user.type';

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Users from "./components/users.component";

import EventBus from "./common/EventBus";
import PrivateRoute from "./components/privateRoute/privateRoute";
import PrivateAdminRoute from "./components/privateRoute/privateAdminRoute";
import { TopMenu } from "./components/top-menu";
import Reports from "./components/reports.component";
import { useNavigate } from "react-router-dom";
import Projects from "./components/projects.component";
import Towers from "./components/towers.component";
import LandlordUploads from "./components/landlord-uploads.component"

type Props = {};

type State = {
  currentUser: IUser | undefined;
  //redirectUrl: string;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      //redirectUrl: ''
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user
      });
    }

    EventBus.on("logout", this.logOut);
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
  }

  //

  logOut() {
    AuthService.logout();
    //this.setState({redirectUrl:'/login'});
    window.location.reload();
    //this.navigate('/login')
    // this.setState({
    //   currentUser: undefined,
    // });
  }

  render() {
    const { currentUser } = this.state;

    return (
      // this.state.redirectUrl!=''?
      // <Navigate to={this.state.redirectUrl}/>
      // :
      <div>
        <TopMenu authUser={currentUser}/>
        
        <div className="container-fluid mt-3">
          <Routes>
            <Route path="/" element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reports" element={
                        <PrivateRoute>
                          <Reports />
                        </PrivateRoute>} />
            <Route path="/users" element={
                        <PrivateRoute>
                          <Users />
                        </PrivateRoute>} />
            <Route path="/projects" element={
                        <PrivateAdminRoute>
                          <Projects />
                        </PrivateAdminRoute>} />
            <Route path="/towers" element={
                        <PrivateAdminRoute>
                          <Towers />
                        </PrivateAdminRoute>} />
            <Route path="/multi-uploads" element={
                        <PrivateAdminRoute>
                          <LandlordUploads />
                        </PrivateAdminRoute>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>

        { /*<AuthVerify logOut={this.logOut}/> */}
      </div>
    );
  }
}

export default App;