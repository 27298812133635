import React, { FunctionComponent, forwardRef, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import ILandlord from '../../types/landlord.type';
import IRefData from '../../types/refdata.type';
import IProject from "../../types/project.type"
import ITower from "../../types/tower.type";
import ILandlordLog from '../../types/landlordlog.type'
import landlordService from "../../services/landlord.service";
import refdataService from "../../services/refdata.service";
import landlordlogService from '../../services/landlordlog.service';
import projectService from "../../services/project.service";
import towerService from "../../services/tower.service";
import landlordtrackingService from '../../services/landlordtracking.service';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './_modal.style';
import { date } from 'yup';
import ILandlordTracking from '../../types/landlordtracking.type';

interface LandlordDetailModalProps {
	onClose: () => void;
    landlordId: number;
	//landlordObj?: ILandlord;
}

export const LandlordDetailModal: FunctionComponent<LandlordDetailModalProps> = props => {
	const [landlordInfo, setLandlordInfo] = useState<ILandlord>();
	const [refDatas, setRefDatas] = useState<IRefData[]>([])
	const [landlordDescp, setLandlordDescp] = useState<string>('');
	const [landlordLogs, setLandlordLogs] = useState<ILandlordLog[]>([]);
	const [systemLogChecked, setSystemLogChecked] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [projects, setProjects] = useState<IProject[]>([])
  	const [towers, setTowers] = useState<ITower[]>([])
	const [landlordTracking, setLandlordTracking] = useState<ILandlordTracking>({});

	const _filterLogs = systemLogChecked ? landlordLogs : landlordLogs.filter(_log => _log.isSystem === systemLogChecked);
	const _contractDateExp = landlordInfo?.expContractFormat != null ? new Date(landlordInfo?.expContractFormat ?? '') : null;
	const _landlordExpReminderDate = landlordTracking?.reminderDateFormat != null ? new Date(landlordTracking?.reminderDateFormat ?? '') : null;

	const onGetLandlordLogs = () => {
		landlordlogService.getByLandlordId(props.landlordId).then(
			response => {
				setLandlordLogs(response.data)
			},
			error => {

			}
		);
	}

	const onGetLandlordInfo = () => {
		landlordService.getById(props.landlordId).then(
			response => {
				setLandlordInfo(response.data);
			},
			error => {
			  //setLandlords([]);
			}
		);
	}

	const onUpdateLandlordInfo = () => {
		landlordService.update(landlordInfo).then(
			response => {
				//console.log('success update' + response.data);
				onGetLandlordLogs();
				setIsEditing(false);
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	const onUpdateTrangThai = (trangthaicd?: number) => {
		landlordService.update({ id:props.landlordId, trangThaiCd:trangthaicd}).then(
			response => {
				setLandlordInfo({...landlordInfo, trangThaiCd:trangthaicd})
				onGetLandlordLogs();
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	const onCancelLandlordInfo = () => {
		onGetLandlordInfo();
		setIsEditing(false);
	}

	const onGetLandlordTracking = () => {
		landlordtrackingService.get(props.landlordId).then(
			response => {
				setLandlordTracking(response.data)
			},
			error => {

			}
		);
	}

	const onUpdateLandlordReminder = (date:Date | null) => {
		setLandlordTracking({...landlordTracking, reminderDateFormat:date}); 
		landlordtrackingService.update({...landlordTracking, reminderDateFormat:date}).then(
			response => {
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	const onUpdateLandlordFollowing = () => {
		var newValue = !landlordTracking?.isFollowing;
		setLandlordTracking({...landlordTracking, isFollowing:newValue}); 

		landlordtrackingService.update({...landlordTracking, isFollowing: newValue}).then(
			response => {
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	useEffect(()=>{
		onGetLandlordInfo();
		onGetLandlordLogs();
		onGetLandlordTracking();
		
		const fetchRefDatas = async () => {
			const refdatas = await refdataService.getAll()
			setRefDatas(refdatas);
		  };    
		fetchRefDatas();
		
		const fetchProjects = async () => {
			const projects = await projectService.getAll()
			setProjects(projects);
		  };    
		fetchProjects();

		const fetchTowers = async () => {
			const towers = await towerService.getAll()
			setTowers(towers);
		};    
    	fetchTowers();
	},[])

	const submitLandlordLog = () => {
		if(landlordDescp === '')
			return;

		landlordlogService.addLandlordLog(props.landlordId, landlordDescp).then(
			response => {
				setLandlordDescp('');
				onGetLandlordLogs();
				toast.success("Cập nhật thành công!", {theme: "colored"})
			},
			error => {

			}
		)
	}

	return (
		<React.Fragment>
			<Message>{landlordInfo?.name}</Message>
			<div><span>Chọn để cập nhật nhanh trạng thái:</span></div>
			<div className='btn-group mt-1 w-100-overflow'>
				{
					refDatas.filter(_r => 'trang_thai' === ''+_r.refCd).map(_item =>
						<a key={_item.id} onClick={()=> { onUpdateTrangThai(_item.id) } } className={`${landlordInfo?.trangThaiCd===_item.id?'active':''} btn btn-warning`}>{_item.name}</a>
					)
				}
			</div>
			<div className='row'>
				<div className='col-md-6 cod-sm-6 col-12'>
				<div className='containter-custom'>
					<div className='form-group'>
						<label className='required'>Bình Luận</label>
						<textarea onChange={ (e) => setLandlordDescp(e.target.value) } value={landlordDescp} className="form-control" placeholder="Nhập nội dung"/>
					</div>
					<div className="d-flex">
						<button disabled={ landlordDescp==='' } onClick={ submitLandlordLog } className="btn btn-primary">Gửi</button>
					</div>
				</div>
				
				<div>
				<div className="form-check">
					<input className="form-check-input" type="checkbox" onChange={()=>setSystemLogChecked(!systemLogChecked)} checked={systemLogChecked}/>
					<label className="form-check-label">
						Hiện logs hệ thống
					</label>
				</div>
					<ul className="list-group">
						{	
							_filterLogs.map(_landlordlog =>
								<li key={_landlordlog.id} className={`comment-item list-group-item d-flex justify-content-between align-items-start ${_landlordlog.isSystem ? 'dark-bg' : ''}`}>
									<div className='description'>
										<b>{_landlordlog.createdBy}</b> - <small>{_landlordlog.createdDate}</small>
										<div dangerouslySetInnerHTML={{ __html: _landlordlog.description ?? '' }} />
									</div>
									{/* <div>
										<div className="badge bg-secondary rounded-pill text-light px-3 py-1 d-block" data-toggle="tooltip" title="-10 (days)">Updating...</div>
									</div> */}
								</li>
							)
						}
					</ul>
					</div>
				</div>
				<div className='col-md-6 cod-sm-6 col-12'>
					<div className='border border-warning mt-3 p-3 metadata-box'>
						<h6>Dữ liệu cá nhân</h6>
							<div className='row'>
								<div className='col'>
									<div className="form-check">
										<input onChange={(e)=>{ onUpdateLandlordFollowing() }} checked={landlordTracking?.isFollowing ?? false} className="form-check-input" type="checkbox"/>
										<label className="form-check-label">
											Đưa vào danh sách theo dõi của tôi
										</label>
									</div>
								</div>
								<div className='col'>
									<DatePicker showIcon isClearable dateFormat="dd/MM/yyyy" selected={_landlordExpReminderDate} onChange={(date)=>{ onUpdateLandlordReminder(date) }} className='form-control'/>
									<small>ℹ️ Chọn ngày để đưa vào danh sách nhắc nhở khi quá hạn</small>									
								</div>
							</div>
					</div>
					<div className='containter-custom'>
					<table className='table table-striped table-bordered'>
						<tbody>
							<tr>
								<th>ID</th>
								<td>{landlordInfo?.id}</td>
							</tr>
							<tr>
								<th>Tên Khách Hàng</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, name:e.target.value})}} className='form-control' value={landlordInfo?.name ?? ''}/>
										: landlordInfo?.name
									}
								</td>
							</tr>
							<tr>
								<th>Dự Án</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.projectID ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, projectID:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											projects.map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Tháp</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.towerID ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, towerID:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											towers.filter(_r => landlordInfo?.projectID === _r.projectID).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Mã căn</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, maCan:e.target.value})}} className='form-control' value={landlordInfo?.maCan ?? ''}/>
										: landlordInfo?.maCan
									}
								</td>
							</tr>
							<tr>
								<th>Email</th>
								<td>
									{ isEditing ? 
										<div>
											<input onChange={(e)=>{setLandlordInfo({...landlordInfo, email:e.target.value})}} className='form-control' value={landlordInfo?.email ?? ''}/>
											<small className="form-text text-muted">Mỗi Email cách nhau bằng dấu phẩy `,`</small>
										</div>
										: landlordInfo?.email
									}
								</td>
							</tr>
							<tr>
								<th>Số ĐT</th>
								<td>
									{ isEditing ? 
										<div>
											<input onChange={(e)=>{setLandlordInfo({...landlordInfo, phoneNo:e.target.value})}} className='form-control' value={landlordInfo?.phoneNo ?? ''}/>
											<small className="form-text text-muted">Mỗi Số ĐT cách nhau bằng dấu phẩy `,`</small>
										</div>
										: landlordInfo?.phoneNo
									}
								</td>
							</tr>
							<tr>
								<th>Giá Bán</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, giaBan:e.target.value})}} className='form-control' value={landlordInfo?.giaBan ?? ''}/>
										: landlordInfo?.giaBan
									}
								</td>
							</tr>
							<tr>
								<th>Giá Thuê</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, giaThue:e.target.value})}} className='form-control' value={landlordInfo?.giaThue ?? ''}/>
										: landlordInfo?.giaThue
									}
								</td>
							</tr>
							<tr>
								<th>Tình Trạng</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.tinhTrangCd ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, tinhTrangCd:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											refDatas.filter(_r => 'tinh_trang' === ''+_r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Nội Thất</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.noiThatCd ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, noiThatCd:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											refDatas.filter(_r => 'noi_that' === ''+_r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Loại Hình</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.loaiHinhCd ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, loaiHinhCd:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											refDatas.filter(_r => 'loai_hinh' === ''+_r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Số PN</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.soPhongNgu ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, soPhongNgu:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											refDatas.filter(_r => 'so_pn' === ''+_r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Hướng</th>
								<td>
									<select disabled={!isEditing} value={landlordInfo?.huongCd ?? 0} onChange={(e)=> { setLandlordInfo({...landlordInfo, huongCd:+e.target.value }) } } className="form-control">
										<option value="0">- Chọn -</option>
										{
											refDatas.filter(_r => 'huong' === ''+_r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
								</td>
							</tr>
							<tr>
								<th>Diện Tích</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, dienTich:e.target.value})}} className='form-control' value={landlordInfo?.dienTich ?? ''}/>
										: landlordInfo?.dienTich
									}
								</td>
							</tr>
							<tr>
								<th>Tầng</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, floorNo:e.target.value})}} className='form-control' value={landlordInfo?.floorNo ?? ''}/>
										: landlordInfo?.floorNo
									}
								</td>
							</tr>
							<tr>
								<th>Căn</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, trucCan:e.target.value})}} className='form-control' value={landlordInfo?.trucCan ?? ''}/>
										: landlordInfo?.trucCan
									}
								</td>
							</tr>
							<tr>
								<th>Địa chỉ</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, address:e.target.value})}} className='form-control' value={landlordInfo?.address ?? ''}/>
										: landlordInfo?.address
									}
								</td>
							</tr>
							<tr>
								<th>Ghi Chú</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, note:e.target.value})}} className='form-control' value={landlordInfo?.note ?? ''}/>
										: landlordInfo?.note
									}
								</td>
							</tr>
							<tr>
								<th>Ngày hết hạn HĐ</th>
								<td>
									{/* {landlordInfo?.expContract} */}
									<DatePicker dateFormat="dd/MM/yyyy" selected={_contractDateExp} onChange={(date)=>{setLandlordInfo({...landlordInfo, expContractFormat:date})}} className='form-control' disabled={!isEditing} />
								</td>
							</tr>
							<tr>
								<th>Ghi Chú HĐ</th>
								<td>
									{ isEditing ? 
										<input onChange={(e)=>{setLandlordInfo({...landlordInfo, contractNote:e.target.value})}} className='form-control' value={landlordInfo?.contractNote ?? ''}/>
										: landlordInfo?.contractNote
									}
								</td>
							</tr>
							{ !isEditing ? 
								<tr>
									<th>Sửa Bởi</th>
									<td>{landlordInfo?.updatedBy}</td>
								</tr> 
							:''}
							{ !isEditing ? 
							<tr>
								<th>TG Sửa</th>
								<td>{landlordInfo?.updatedDate}</td>
							</tr>
							:''}
							{ !isEditing ? 
							<tr>
								<th>Tạo Bởi</th>
								<td>{landlordInfo?.createdBy}</td>
							</tr>
							:''}
							{ !isEditing ? 
							<tr>
								<th>TG Tạo</th>
								<td>{landlordInfo?.createdDate}</td>
							</tr>
							:''}
						</tbody>
					</table>
					{ isEditing ? 
						<div>
							<button onClick={() => { onUpdateLandlordInfo() }} type="button" className="btn btn-success mr-2">Save</button>
							<button onClick={()=> { onCancelLandlordInfo() }} type="button" className="btn btn-secondary">Cancel</button>
						</div>
						: <button onClick={()=> { setIsEditing(true) }} type="button" className="btn btn-primary">Cập Nhật Khách Hàng</button>
					}
					</div>
				</div>
			</div>
			<div className='modal-footer'>
				{/* <YesButton onClick={props.onConfirm}>Yes</YesButton> */}
				{/* <NoButton onClick={props.onClose}>Close</NoButton> */}
				<button type="button" className="btn btn-secondary" onClick={props.onClose}>Close</button>
			</div>
		</React.Fragment>
	);
};