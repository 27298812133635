import { Navigate } from "react-router-dom";
import authService from "../../services/auth.service";


interface IProps {
  children: JSX.Element;
}

export default function privateAdminRoute({ children }: IProps) {
  const userAuth = authService.getCurrentUser();

  return (
    userAuth != null && userAuth.username == 'admin' ? children : <Navigate to="/login"/>
  );
}
