import { Component } from "react";

//import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import IUserActivity from "../types/user-activity.type";
import reportService from "../services/report.service";
import Table from 'react-bootstrap/Table';
import { Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {};

type State = {
  user_activities: IUserActivity[];
  fromDate: Date | null;
  toDate: Date |  null;
}

export default class Reports extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user_activities: [],
      fromDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
      toDate: new Date(),
    };
  }

  componentDidMount() {
    reportService.getUserActivityRpt(this.state.fromDate, this.state.toDate).then(
      response => {
        this.setState({
          user_activities: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <Container>
        <ToastContainer />
        <h4>Báo cáo hiệu suất Nhân viên</h4>
        <DatePicker showIcon isClearable dateFormat="dd/MM/yyyy" selected={this.state.fromDate} onChange={(date)=>{ this.setState({fromDate: date}) }} className='form-control'/>
        <DatePicker showIcon isClearable dateFormat="dd/MM/yyyy" selected={this.state.toDate} onChange={(date)=>{ this.setState({toDate: date}) }} className='form-control'/>
        <Button variant="secondary" onClick={()=>{this.componentDidMount()}}><FontAwesomeIcon icon={faCheck} /> Báo Cáo</Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Username</th>
                <th>Số Khách hàng</th>
                <th>Số Comment</th>
              </tr>
            </thead>
            <tbody>
              {this.state.user_activities.map(ua => (
                <tr key={ua.username}>
                  <td>{ua.username}</td>
                  <td>{ua.totalLandlords}</td>
                  <td>{ua.totalLandlordLogs}</td>
                </tr>
              ))}
            </tbody>
            </Table>
      </Container>
    );
  }
}
