import axios from 'axios';
import IProject from '../types/project.type';
import authHeader from './auth-header';

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class ProjectService {
  getAll = async() => {
    const projectsStr = localStorage.getItem("_projects");
    if (projectsStr) 
      return JSON.parse(projectsStr);
    else
    {
      return axios.get(API_URL + 'projects?status=true', { headers: authHeader() })
        .then(response => {
          localStorage.setItem("_projects", JSON.stringify(response.data));
          return response.data;
        });
    }
  }

  getAllForManage = async() => {
    return axios.get(API_URL + 'projects', { headers: authHeader() });
  }

  create(model:IProject){
    return axios.post(API_URL + 'projects', model, { headers: authHeader() })
  }

  update(model:IProject){
    return axios.post(API_URL + 'projects/' + model.id, model, { headers: authHeader() })
  }
}

export default new ProjectService();
