import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.centralhomes.vn/";
//const API_URL = "http://localhost:4000/";

class ReportService {
  getUserActivityRpt(from:Date|null, to:Date|null) {
    return axios.post(API_URL + 'reports/user-activity', {from, to}, { headers: authHeader() });
  }
}

export default new ReportService();
